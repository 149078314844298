var Cameraland = Cameraland || {};

Cameraland.StickyHeader = class {
    constructor(options) {
        this.toggleSticky = this.toggleSticky.bind(this);
        this.setMobile = this.setMobile.bind(this);
        this.setDesktop = this.setDesktop.bind(this);

        this.options = $j.extend({}, {
            headerSelector: '.header-top',
            navigationSelector: '#nav',
            destinationSelectorSticky: '.sticky-header-cat',
            destinationSelectorNonSticky: '.header-nav'
        }, options);

        enquire.register('screen and (min-width:' + (bp.medium + 1) + 'px)', {
            match: this.setDesktop,
            unmatch: this.setMobile
        });

        if ($j(window).width() < 764) {
            this.setStickyHeaderMarginHeight();
        }
    }

    toggleSticky() {
        const headerSelector = $j(this.options.headerSelector);
        const navigationSelector = $j(this.options.navigationSelector);
        const destinationSelectorSticky = $j(this.options.destinationSelectorSticky);
        const destinationSelectorNonSticky = $j(this.options.destinationSelectorNonSticky);

        if($j(window).scrollTop() > 55 ) {
            headerSelector.addClass('sticky');
            $j('body').addClass('header-sticky');
            navigationSelector.appendTo(destinationSelectorSticky);

        } else {
            headerSelector.removeClass('sticky');
            $j('body').removeClass('header-sticky');
            navigationSelector.appendTo(destinationSelectorNonSticky);
        }
    }

    setStickyHeaderMarginHeight() {
        const marginElement = $j('#nav');
        const headerElements = [$j('.alertBox')];
        let height = 0;

        headerElements.forEach((element) => {
            height += element.outerHeight();
        });

        marginElement.css('cssText', 'top: '+height+'px !important');
    }

    setMobile() {
        const headerSelector = $j(this.options.headerSelector);
        const navigationSelector = $j(this.options.navigationSelector);
        const destinationSelectorNonSticky = $j(this.options.destinationSelectorNonSticky);

        headerSelector.removeClass('sticky');
        $j('body').removeClass('header-sticky');
        navigationSelector.appendTo(destinationSelectorNonSticky);

        $j(window).on('resize', () => {
            this.setStickyHeaderMarginHeight();
        });
    }

    setDesktop() {
        let scrollTimer;

        this.toggleSticky();

        $j(window).on('scroll', () => {
            cancelAnimationFrame(scrollTimer);
            scrollTimer = requestAnimationFrame(this.toggleSticky);
        });
    }
};

$j(function () {
    new Cameraland.StickyHeader();
});